<template>
  <div>
    <Header>文化惠民促销区</Header>
    <div class="wenhua">
      <div
        class="wenhuaItem"
        @click="bazaar(item)"
        v-for="item in promotionList"
        :key="item.id"
      >
        <div class="wenhuaItemBox">
          <div class="tag" v-if="item.count">剩余:{{ item.count }}</div>
          <div class="tag desable" v-else>已售罄</div>
          <img :src="item.img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      promotionList: [],
    };
  },
  mounted() {
    this.getPromotionList();
  },
  methods: {
    bazaar(item) {
      console.log(item);
      if (!item.count) return;
      this.$router.push({
        path: "/bazaar",
        query: {
          id: item.id,
          goodsId: item.goodsId,
          entrustId: item.entrust_id,
          hideAll: true,
          userId: item.userId,
        },
      });
      // this.getGoodmarketlist();
    },
    getPromotionList() {
      this.$api.promotionList().then((e) => {
        this.promotionList = e.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wenhua {
  margin: 10px 0;
  display: flex;
  align-items: center;
  // justify-content: space-around;
  padding: 0 10px 0;
  box-sizing: border-box;
  position: relative;
  flex-wrap: wrap;
  .wenhuaItem {
    width: 50%;
    height: 160px;
    background: #fff;
    margin-bottom: 20px;
    padding: 0 10px;
    box-sizing: border-box;
    .wenhuaItemBox {
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .tag {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 12px;
      color: #f8f9fb;
      height: 20px;
      background: linear-gradient(99deg, #f46e0c 0%, #ffba00 99%);
      box-shadow: 0px 2px 4px 0px rgba(240, 11, 9, 0.28);
      border-radius: 5px 0px 13px 0px;
      padding: 0 5px;
      &.right1 {
        border-radius: 0px 5px 0px 13px;
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
      }
      &.desable {
        box-shadow: 0px 2px 4px 0px rgba(181, 181, 181, 0.28);
        background: rgb(181, 181, 181);
        color: #fff;
      }
    }
    &:nth-child(even) {
      .tag {
        border-radius: 0px 5px 0px 13px;
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
      }
    }
  }
}
</style>